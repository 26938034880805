var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: {
        fullscreen: _vm.$vuetify.breakpoint.smAndDown,
        persistent: "",
        transition: "dialog-bottom-transition",
        width: "90vw",
      },
      model: {
        value: _vm.show,
        callback: function ($$v) {
          _vm.show = $$v
        },
        expression: "show",
      },
    },
    [
      _c(
        "v-card",
        {
          staticClass: "pa-0 ma-0",
          staticStyle: { "border-radius": "0" },
          attrs: { color: "cardDefault", flat: "" },
        },
        [
          _c(
            "v-toolbar",
            {
              staticClass: "mb-4",
              attrs: { color: "primary", dark: "", "max-height": "56" },
            },
            [
              _c("v-toolbar-title", [
                _vm._v(
                  " " +
                    _vm._s(_vm.formatTimelineText("history", "history")) +
                    " "
                ),
              ]),
              _c("v-spacer"),
              _c("asyent-button", {
                attrs: {
                  "button-icon": "mdi-close",
                  "button-tooltip": _vm.ae$prefixLng(
                    _vm.serviceName,
                    "history.close"
                  ),
                  dark: "",
                  icon: "",
                },
                on: { confirmed: _vm.hide },
              }),
            ],
            1
          ),
          _c(
            "v-card-text",
            {
              style: {
                "max-height": "75vh",
              },
            },
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { staticClass: "pb-0", attrs: { cols: "3" } },
                    [
                      _vm.dataLoading
                        ? _c("v-skeleton-loader", {
                            attrs: {
                              type: "list-item-avatar-three-line, list-item-avatar-three-line, list-item-avatar-three-line",
                            },
                          })
                        : _vm._e(),
                      _c(
                        "div",
                        { staticClass: "timeline-container pr-5" },
                        [
                          _c(
                            "v-timeline",
                            { attrs: { dense: "" } },
                            _vm._l(_vm.items, function (item) {
                              return _c(
                                "v-timeline-item",
                                {
                                  key: item.displayId,
                                  attrs: {
                                    color: _vm.setActionColor(),
                                    right: "",
                                    small: "",
                                  },
                                },
                                [
                                  _c(
                                    "v-card",
                                    {
                                      class: {
                                        selected:
                                          item.versionId ===
                                          _vm.selectedVersion,
                                      },
                                    },
                                    [
                                      _c(
                                        "v-card-text",
                                        [
                                          _c(
                                            "v-row",
                                            { attrs: { dense: "" } },
                                            [
                                              _c(
                                                "v-col",
                                                {
                                                  attrs: {
                                                    cols: "12",
                                                    md: "12",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "font-weight-black",
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.ae$prefixLng(
                                                              _vm.serviceName,
                                                              "history.version"
                                                            )
                                                          ) +
                                                          " : "
                                                      ),
                                                    ]
                                                  ),
                                                  _c("span", [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(item.displayId) +
                                                        " "
                                                    ),
                                                  ]),
                                                  _c("v-divider"),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-col",
                                                {
                                                  attrs: {
                                                    cols: "12",
                                                    md: "12",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "font-weight-black",
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.ae$prefixLng(
                                                              _vm.serviceName,
                                                              "history.operation"
                                                            )
                                                          ) +
                                                          " : "
                                                      ),
                                                    ]
                                                  ),
                                                  _c("span", [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.formatTimelineText(
                                                            "history.operations",
                                                            item.documentAction
                                                          )
                                                        ) +
                                                        " "
                                                    ),
                                                  ]),
                                                ]
                                              ),
                                              _c(
                                                "v-col",
                                                {
                                                  attrs: {
                                                    cols: "12",
                                                    md: "12",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "font-weight-black",
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.ae$prefixLng(
                                                              _vm.serviceName,
                                                              "history.user"
                                                            )
                                                          ) +
                                                          " : "
                                                      ),
                                                    ]
                                                  ),
                                                  _c("span", [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          item.documentUserName
                                                        ) +
                                                        " "
                                                    ),
                                                  ]),
                                                ]
                                              ),
                                              _c(
                                                "v-col",
                                                {
                                                  attrs: {
                                                    cols: "12",
                                                    md: "12",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "font-weight-black",
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.ae$prefixLng(
                                                            _vm.serviceName,
                                                            "history.status"
                                                          )
                                                        ) + " : "
                                                      ),
                                                    ]
                                                  ),
                                                  _c("span", [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.formatTimelineText(
                                                            "history.operations",
                                                            item.documentStatus
                                                          )
                                                        ) +
                                                        " "
                                                    ),
                                                  ]),
                                                ]
                                              ),
                                              _c(
                                                "v-col",
                                                {
                                                  attrs: {
                                                    cols: "12",
                                                    md: "12",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "font-weight-black",
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.ae$prefixLng(
                                                              _vm.serviceName,
                                                              "history.dateTime"
                                                            )
                                                          ) +
                                                          " : "
                                                      ),
                                                    ]
                                                  ),
                                                  _c("span", [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.ae$formatDateTime(
                                                            item.documentUpdatedAt
                                                          )
                                                        ) +
                                                        " "
                                                    ),
                                                  ]),
                                                ]
                                              ),
                                              _c(
                                                "v-col",
                                                {
                                                  attrs: {
                                                    cols: "12",
                                                    md: "12",
                                                  },
                                                },
                                                [
                                                  _c("asyent-button", {
                                                    key: item.versionId,
                                                    attrs: {
                                                      "button-icon": "mdi-eye",
                                                      "button-tooltip":
                                                        _vm.ae$prefixLng(
                                                          _vm.serviceName,
                                                          "history.view"
                                                        ),
                                                      text: "",
                                                      "aria-label": "View",
                                                      outlined: "",
                                                    },
                                                    on: {
                                                      confirmed: function (
                                                        $event
                                                      ) {
                                                        return _vm.selectVersion(
                                                          item.versionId
                                                        )
                                                      },
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "9" } },
                    [
                      _vm.selectedVersion
                        ? _c(_vm.componentName, {
                            tag: "component",
                            attrs: {
                              id: _vm.id,
                              "no-header": "",
                              history: "",
                              "version-id": _vm.selectedVersion,
                              rop: "history",
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }